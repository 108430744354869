import { Component, LOCALE_ID, OnDestroy, OnInit, inject } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject } from 'rxjs';
import { filter, skip, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CampaignData } from './api/models/campaign-data';
import { User } from './api/models/user';
import { VideoWatchedParams } from './api/models/video-watched-params';
import { CampaignService } from './api/services/campaign.service';
import { RouterStateParams } from './app-routing.serializer';
import { BookmarksActions } from './bookmarks/bookmarks.actions';
import { ContentOnlyService } from './core/content-only/content-only.service';
import { CoreActions } from './core/core.actions';
import { NextAfterLoginService } from './core/next-after-login/next-after-login.service';
import { PageRenderedService } from './core/page-rendered/page-rendered.service';
import { SectionIdService } from './core/section-id/section-id.service';
import { SeoTagService } from './core/seo-tag/seo-tag.service';
import { ViewportService } from './core/viewport/viewport.service';
import { RedirectedUserModalComponent } from './shared/redirected-user-modal/redirected-user-modal.component';
import { UserActions } from './user/user.actions';
import { RailsLocalStorageItem } from './video/video/video.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private language = inject(LOCALE_ID);
  public isLoggedIn: boolean;
  private isCampaignDataTrigger = false;

  @Select(state => state.user.user) user$: Observable<User>;
  @Select(state => state.core.tracking_events) tracking_events$: Observable<
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    Array<any>
  >;
  @Select(state => state.core.leanplum_events) leanplum_events$: Observable<
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    Array<any>
  >;
  @Select(state => state.core.contentOnly) contentOnly$: Observable<boolean>;
  @Select(state => state.core.redirected) redirected$: Observable<boolean>;

  routerState$: Observable<RouterStateParams>;

  constructor(
    private contentOnlyService: ContentOnlyService,
    private cookieService: CookieService,
    private gtmService: GoogleTagManagerService,
    private nextAfterLoginService: NextAfterLoginService,
    private pageRenderedService: PageRenderedService,
    private router: Router,
    private sectionIdService: SectionIdService,
    private seoTagService: SeoTagService,
    private store: Store,
    private viewportService: ViewportService,
    private campaignService: CampaignService,
  ) {}

  ngOnInit() {
    // load services at startup
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.store.dispatch(new CoreActions.SetRedirected(false));
        this.store.dispatch(new CoreActions.GetTrackingEvents());
      }
    });

    this.contentOnlyService.subscribeToUrlChanges();
    this.nextAfterLoginService.init();
    this.sectionIdService.subscribeToUrlChanges();
    this.seoTagService.startSeoService();
    this.pageRenderedService.init();
    this.viewportService.init();

    this.store.dispatch(new UserActions.FetchCurrentUser());
    this.store.dispatch(new CoreActions.FetchSiteParams());
    this.user$.pipe(takeUntil(this.destroy$)).subscribe(user => {
      if (user) {
        this.store.dispatch(new BookmarksActions.FetchLists());
        localStorage.setItem('userId', user.id);
        this.fireWatchEvent();
      } else {
        localStorage.removeItem('userId');
      }
      this.gtmService.addGtmToDom().then(() => {
        this.gtmEvents();
        this.router.events
          .pipe(filter(item => item instanceof NavigationEnd))
          .subscribe((item: NavigationEnd) => {
            const virtualTag = {
              event: 'VirtualPageview',
              virtualPageURL: item.url,
              virtualPageTitle: 'view',
            };
            this.sendToGTM(virtualTag);
          });
      });
    });

    this.user$.pipe(takeUntil(this.destroy$), skip(1)).subscribe(user => {
      this.isLoggedIn = !!user;
      //Method to trigger campaign data with utm params or save it in local storage
      this.triggerCampaignData();
    });

    this.routerState$ = this.store.select(state => state.router.state);
    this.openRedirectModal();
  }
  //Method to trigger campaign data with utm params or save it in local storage
  private triggerCampaignData(): void {
    this.routerState$.pipe(takeUntil(this.destroy$)).subscribe(routerState => {
      if (this.isCampaignDataTrigger) return;
      if (
        routerState?.queryParams?.utm_source ||
        routerState?.queryParams?.utm_medium ||
        routerState?.queryParams?.utm_campaign
      ) {
        const campaignData = routerState.queryParams as CampaignData;
        if (this.isLoggedIn) this.saveCampaignData(campaignData);
        else {
          localStorage.setItem('campaignData', JSON.stringify(campaignData));
          localStorage.setItem(
            'campaignDataTime',
            new Date().getTime().toString(),
          );
        }
      } else if (localStorage.getItem('campaignData') && this.isLoggedIn) {
        const campaignData = JSON.parse(localStorage.getItem('campaignData'));
        this.saveCampaignData(campaignData);
      } else if (localStorage.getItem('campaignData')) {
        const time = parseInt(localStorage.getItem('campaignDataTime'));
        const daysDifference = Math.floor(
          (new Date().getTime() - time) / (1000 * 60 * 60 * 24),
        );
        if (daysDifference >= 30) {
          this.removeCampaignData();
        }
      }
    });
  }
  //Method to save campaign data
  private saveCampaignData(campaignData: CampaignData): void {
    this.isCampaignDataTrigger = true;
    this.campaignService.saveCampaignData(campaignData).subscribe({
      next: () => {
        this.removeCampaignData();
      },
      error: () => {
        this.isCampaignDataTrigger = false;
      },
    });
  }
  //Method to remove campaign data from local storage
  private removeCampaignData(): void {
    localStorage.removeItem('campaignData');
    localStorage.removeItem('campaignDataTime');
  }

  trackingEvents() {
    this.tracking_events$
      .pipe(takeUntil(this.destroy$))
      .subscribe(tracking_events => {
        for (const string_event of tracking_events) {
          const ev = JSON.parse(string_event);
          if (ev.event === 'new_user') {
            const couponCode = ev.properties?.coupon_code;
            if (couponCode) {
              this.sendToGTM({
                event: 'registration_with_coupon_complete',
                origin: 'normal',
                DL_coupon_code: couponCode,
                DL_new_user: true,
              });
            } else {
              this.sendToGTM({
                event: 'registration_complete',
                origin: 'normal',
                DL_new_user: true,
              });
            }
          }
        }
      });
    this.leanplum_events$
      .pipe(takeUntil(this.destroy$))
      .subscribe(leanplum_events => {
        if (leanplum_events.length >= 1) {
          this.sendToGTM({
            DL_leanplum_events: leanplum_events,
          });
        }
      });
  }

  gtmEvents() {
    // TODO: user will always be undefined at startup because it has to be fetched from the BE!
    const user = this.store.selectSnapshot(state => state.user.user);

    const variables = {
      application: 'web',
      channel: 'web',
      subchannel: 'unknown',
      channel_subtype: 'direkt',
      app_version: '',
      domain: document.location.origin,
      environment: environment.name,
    };

    const gtmTag = {
      event: 'load_gdpr_banner',
      gdprConsent: this.cookieService
        .get('_ye_gdpr_active_group')
        .replace(/\|/g, ','),
      origin: 'normal',
      DL_userId: user?.id,
      DL_mixpanel_token: environment.trackingMixpanelToken,
      DL_environment: environment.name,
      DL_leanplum_variables: JSON.stringify(variables),
      DL_leanplum_current_user: user ? user.id : null,
      'gtm.element.dataset.domainScript': environment[this.language].oneTrustID,
    };
    this.sendToGTM(gtmTag);

    if (user) {
      this.sendToGTM({ event: 'mixpanel_identify' });
    }

    if (user?.gdpr_preferences) {
      this.sendToGTM({
        event: 'load_gdpr_consent',
        gdprConsent: user.gdpr_preferences,
      });
    }
    this.trackingEvents();
  }

  fireWatchEvent() {
    const watchedVideoEventKey: string = Object.keys(localStorage).find(key =>
      key.startsWith('watchedVideo-'),
    );
    if (watchedVideoEventKey) {
      const localStorageItem: RailsLocalStorageItem = JSON.parse(
        localStorage.getItem(watchedVideoEventKey),
      );
      const videoWatchedPayload: VideoWatchedParams = {
        breadcrumb: localStorageItem.breadcrumb,
        file_id: parseInt(localStorageItem.videoWatchObj?.file_id),
        percentage: parseInt(localStorageItem.videoWatchObj?.percentage),
        session: localStorageItem.videoWatchObj.session,
        unit_index: parseInt(localStorageItem.unit_index),
        video_id: parseInt(localStorageItem.videoWatchObj?.video_id),
        program_slug: localStorageItem.program_slug,
      };
      this.store.dispatch(new UserActions.VideoWatched(videoWatchedPayload));
      this.store.dispatch(new CoreActions.GetTrackingEvents());
      localStorage.removeItem(watchedVideoEventKey);
      sessionStorage.removeItem('session_generated_id');
    }
  }

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  async sendToGTM(tag: any) {
    await this.gtmService.pushTag(tag);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private openRedirectModal(): void {
    this.routerState$.pipe(takeUntil(this.destroy$)).subscribe(routerState => {
      if (
        routerState?.queryParams?.redirected === 'true' &&
        !sessionStorage.getItem('redirected')
      ) {
        sessionStorage.setItem('redirected', 'true');
        this.store.dispatch(
          new CoreActions.OpenModal(
            RedirectedUserModalComponent.MODAL_ID,
            RedirectedUserModalComponent,
            {
              windowClass: 'redirected-user-modal-window',
            },
          ),
        );
      }
    });
  }
}
