@if(teaser){
<a [href]="'/meine-videos/meine-listen/' + teaser.id" class="is-block bookmarks-list-teaser">
  @if(locale === 'en') {
  <p *ngIf="teaser.is_followed" class="is-position-absolute is-uppercase is-z-index-1 is-marginless followed-flag">
    Followed</p>
  }
  <img class="is-block image"
    [src]="teaser.image_url || 'https://cdn.yogaeasy.de/production/uploads/components/magazine-article-teaser/teaser_fallback_image.jpg'" />
  <div class="text-block is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
    <h4 class="teaser-title">{{ teaser.title }}</h4>
    <span class="videos-count">
      @if (teaser.number_of_items === 1) {
      <ng-container i18n>1 Video</ng-container>
      } @else {
      <ng-container i18n>{{ teaser.number_of_items }} Videos</ng-container>
      }
    </span>
  </div>
</a>
}