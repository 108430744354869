import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CampaignData } from '../models/campaign-data';
import { User } from '../models/user';
import { UserProfileForm } from '../models/user-profile-form';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private httpClient: HttpClient,
    @Inject(LOCALE_ID) public locale: string,
  ) {}
  path = `${environment.apiPath}/v1/user/`;

  getCurrentUser(): Observable<User> {
    return this.httpClient
      .get<User>(`${this.path}current`)
      .pipe(catchError(this.handleError));
  }

  getProfileForm(): Observable<UserProfileForm> {
    return this.httpClient
      .get<UserProfileForm>(`${environment.apiPath}/v1/users_base/profile`)
      .pipe(catchError(this.handleError));
  }

  signOut() {
    const signOutPath = `${environment.apiPath}/v1/jwt/sign_out`;
    return (
      this.httpClient
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        .delete<any>(signOutPath)
        .pipe(catchError(this.handleError))
    );
  }

  requestNewPassword(email: string) {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    return this.httpClient.post<any>(
      `${this.path}reset_password?email=${email}`,
      {},
    );
  }

  setNewPassword(password: string, token: string) {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    return this.httpClient.post<any>(`${this.path}update_password`, {
      password,
      reset_password_token: token,
    });
  }

  signIn(email: string, password: string, rememberMe: boolean) {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    return this.httpClient.post<any>(`${this.path}login`, {
      email,
      password,
      remember_me: rememberMe,
    });
  }

  updateFirstName(firstName: string): Observable<{ user: User }> {
    return this.httpClient.patch<{ user: User }>(this.path, {
      firstname: firstName,
    });
  }
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  updateProfileForm(profileForm: UserProfileForm): Observable<any> {
    return this.httpClient.post(
      `${environment.apiPath}/v1/users_base/set_profile`,
      profileForm,
    );
  }

  signUp(
    firstname: string,
    lastname: string,
    email: string,
    password: string,
    country: string,
    tos_accepted: boolean,
    wants_newsletter: boolean,
  ) {
    const locale = this.locale;
    const campaignData = this.getCampaignData();
    return (
      this.httpClient
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        .post<any>(`${this.path}sign_up`, {
          firstname,
          lastname,
          email,
          password,
          country,
          tos_accepted,
          wants_newsletter,
          locale,
          utm: campaignData,
        })
        .pipe(catchError(this.handleError))
    );
  }

  handleError(err: HttpErrorResponse) {
    let errorMessage = '';

    if (err.error instanceof Error) {
      errorMessage = `Error occurred ${err.error.message}`;
    } else {
      errorMessage = err.error.error;
    }

    return throwError(errorMessage);
  }

  public getCampaignData(): CampaignData {
    if (!localStorage.getItem('campaignData')) {
      return {} as CampaignData;
    }
    return JSON.parse(localStorage.getItem('campaignData')) as CampaignData;
  }
}
